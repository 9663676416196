<template>
  <div>
    <contacts-component />
    <footer-component />
  </div>
</template>

<script>
import ContactsComponent from "@/components/contact-info/ContactsComponent";
import FooterComponent from "@/layouts/app-layout/footer/FooterComponent";
import { changeInformationSEO } from "@/providers/KeywordsServices";

export default {
  name: "Contacts",
  title: "Contactos | Baja California Health Tourism",
  components: {
    ContactsComponent,
    FooterComponent,
  },
  mounted() {
    changeInformationSEO();
  }
};
</script>

<style scoped></style>
